import React, { useRef, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from "react-markdown"
import './register.scss';
import { inViewOptions, contentItemStagger, contentItem, fadeInFromLeft, fadeInFromRight } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import SubscribeNew from "@Components/forms/subscribenew"
const Register = (props) => {
	const nameRef = useRef();
	const emailRef = useRef();
	const [enteredName, setEnteredName] = useState('');
	const [enteredEmail, setEnteredEmail] = useState('');
const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || ""


	const nameBlurHandler = () => {
		setEnteredName(nameRef.current.value);
	}
	const emailBlurHandler = () => {
		setEnteredEmail(nameRef.current.value);
	}
	
	const submitHandler = (event) => {
		event.preventDefault();

	}

	const myAccountHandler=()=>{
		return typeof window!=="undefined"?window.location?.assign(myaccountURL):""
	}
	return (
		<InView {...inViewOptions}>
		{({ ref, inView }) => (
			<motion.section
				className="register my-acc"
				ref={ref}
				initial="hidden"
				animate={inView ? "visible" : "hidden"}
				variants={contentItemStagger}
				
			>
			<Container>
				<Row>
					<Col sm="12">
					{props?.Title && <motion.div variants={fadeInFromLeft}><ReactMarkdown source={props?.Title} allowDangerousHtml /></motion.div>}
							{props?.Content && <motion.div variants={fadeInFromLeft}><ReactMarkdown source={props?.Content} allowDangerousHtml /></motion.div>}
						
							<motion.div variants={contentItem}  className="form-wrap">
								{/* <form onSubmit={submitHandler}>
									<div className="form-content">
										<div className="input-wrap">
											<input type="text" className="form-control" onBlur={nameBlurHandler} placeholder="Name" ref={nameRef} />
										</div>
										<div className="input-wrap">
											<input type="email" className="form-control" onBlur={emailBlurHandler} placeholder="Email Address" ref={emailRef} />
										</div>
										<div className="btn-wrap">
											<button className="btn">Register</button>
										</div>
									</div>
								</form> */}

								<div className="btn-wrap my-acc-register">
									<button className="btn" onClick={()=>myAccountHandler()}>Register</button>
								</div>
								
								{/* <SubscribeNew isHome={true} formName={props?.Title?.replace( /(<([^>]+)>)/ig, '')}/> */}
							</motion.div>
					</Col>
				</Row>
			</Container>
			</motion.section>
			)}
		</InView>
	)
}

export default Register;
